import { ComponentProps, ComponentType, ForwardedRef, forwardRef, MouseEventHandler, ReactNode } from 'react';
import { css, styled } from '../../stitches.config';
import { assertUnreachable } from '../../utils/assert';
import { spinnerAnimation } from '../feedback/Spinner';
import { Text } from '../typography/Text';
import { Kbd } from '@radix-ui/themes';

export const ButtonContainer = styled('button', {
  display: 'flex',
  gap: '$small',
  justifyContent: 'center',
  alignItems: 'center',
  paddingInline: '$medium',
  paddingBlock: '$small',
  border: 0,
  borderRadius: '$small',
  width: 'fit-content',
  cursor: 'pointer',
  textDecoration: 'none',
  outlineOffset: 0,
  whiteSpace: 'nowrap',
  transition:
    'background $medium ease, color $medium ease, border-color $medium ease, outline-offset $fast ease',
  '&:disabled': {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  '&:focus-visible': {
    outlineOffset: 4,
    outlineWidth: 2,
  },
  '@smallDevices': {
    whiteSpace: 'pre-wrap',
  },
  defaultVariants: {
    variant: 'primary',
  },
  variants: {
    loading: {
      true: {
        '&:disabled': {
          cursor: 'wait',
        },
      },
    },
    block: {
      true: {
        width: '100%',
        boxSizing: 'border-box',
      },
    },
    tint: {
      silvermist: {
        color: '$silvermist-500',
        '&:not(:disabled):hover': {
          backgroundColor: '$silvermist-50',
          color: '$silvermist-700',
        },
      },
      lupine: {
        color: '$lupine-500',
        '&:not(:disabled):hover': {
          backgroundColor: '$lupine-50',
          color: '$lupine-700',
        },
      },
      pineglade: {
        color: '$pineglade-500',
        '&:not(:disabled):hover': {
          backgroundColor: '$pineglade-50',
          color: '$pineglade-700',
        },
      },
      bloodmoon: {
        color: '$bloodmoon-500',
        '&:not(:disabled):hover': {
          backgroundColor: '$bloodmoon-50',
          color: '$bloodmoon-700',
        },
      },
      sunbark: {
        color: '$sunbark-500',
        '&:not(:disabled):hover': {
          backgroundColor: '$sunbark-50',
          color: '$sunbark-700',
        },
      },
    },
    variant: {
      spotlight: {
        color: '$white',
        backgroundColor: '$lupine-600',
        border: '1px solid $lupine-800',
        boxShadow: '$button',
        '&:not(:disabled):hover': {
          backgroundColor: '$lupine-500',
          borderColor: '$lupine-600',
        },
        '&:focus-visible': {
          outlineColor: '$lupine-600',
        },
      },
      primary: {
        color: '$white',
        backgroundColor: '$silvermist-950',
        border: '1px solid $silvermist-950',
        boxShadow: '$button',
        '&:not(:disabled):hover': {
          backgroundColor: '$silvermist-500',
          borderColor: '$silvermist-600',
        },
        '&:focus-visible': {
          outlineColor: '$silvermist-950  ',
        },
      },
      secondary: {
        color: '$silvermist-950',
        backgroundColor: '$white',
        border: '1px solid $silvermist-200',
        boxShadow: '$button',
        '&:not(:disabled):hover': {
          color: '$lupine-600',
          borderColor: '$lupine-600',
          backgroundColor: '$lupine-50',
        },
        '&:focus-visible': {
          outlineColor: '$silvermist-950',
        },
      },
      tertiary: {
        paddingHorizontal: 0,
        color: '$silvermist-950',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        textUnderlineOffset: 3,
        '&:not(:disabled):hover': {
          color: '$lupine-600',
        },
        '&:focus-visible': {
          outlineColor: '$silvermist-950',
        },
      },
      destructive: {
        color: '$white',
        backgroundColor: '$bloodmoon-600',
        border: '1px solid $bloodmoon-800',
        boxShadow: '$button',
        '&:not(:disabled):hover': {
          backgroundColor: '$bloodmoon-500',
          borderColor: '$bloodmoon-600',
        },
        '&:focus-visible': {
          outlineColor: '$bloodmoon-600',
        },
      },
      destructiveSecondary: {
        color: '$bloodmoon-600',
        backgroundColor: '$white',
        border: '1px solid $bloodmoon-200',
        boxShadow: '$button',
        '&:not(:disabled):hover': {
          borderColor: '$bloodmoon-600',
          backgroundColor: '$bloodmoon-50',
        },
        '&:focus-visible': {
          outlineColor: '$bloodmoon-600',
        },
      },
      inline: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: 0,
        color: '$silvermist-950',
        background: 'transparent',
        textDecoration: 'underline',
        textUnderlineOffset: 3,
        borderRadius: '$square',
        fontWeight: '$semibold',
        cursor: 'pointer',
        fontSize: 'small',
        transition: 'outline-offset $fast ease',
        '&:not(:disabled):hover': {
          color: '$lupine-600',
        },
        '&:focus-visible': {
          outlineColor: '$silvermist-950',
        },
      },
      subtleInline: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: '$xxxsmall',
        padding: 0,
        color: '$silvermist-600',
        background: 'transparent',
        textDecoration: 'underline',
        textUnderlineOffset: 3,
        borderRadius: '$square',
        fontWeight: '$semibold',
        cursor: 'pointer',
        fontSize: 'small',
        transition: 'color $medium ease',
        '&:not(:disabled):hover': {
          color: '$lupine-600',
        },
        '&:focus-visible': {
          outlineColor: '$silvermist-500',
        },
      },
      subtle: {
        color: '$silvermist-950',
        '&:not(:disabled):hover': {
          color: '$lupine-600',
          backgroundColor: '$silvermist-100',
          borderColor: '$silvermist-300',
        },
        '&:focus-visible': {
          outlineColor: '$silvermist-950',
        },
      },
      iconOnly: {
        padding: '$xsmall',
        color: '$silvermist-600',
        backgroundColor: 'transparent',
        '&:not(:disabled):hover': {
          backgroundColor: '$silvermist-50',
          color: '$silvermist-900',
        },
        '&:focus-visible': {
          outlineColor: '$silvermist-950',
        },
      },
    },
  },
});

const iconClassName = css({
  size: 32,
  marginVertical: -5,
  paddingTop: '5px',
  paddingBottom: '5px',
})();

const ConicGradient = styled('div', {
  width: '100%',
  height: '100%',
  background:
    'conic-gradient(from 90deg at 50% 50%, transparent 0deg, currentColor 270.03deg, transparent 360deg);',
});

const ButtonLoadingIcon = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <clipPath id="clip">
      <path
        d="M8 0.999886C8 0.447664 7.5506 -0.0064405 7.00269 0.0624014C5.77383 0.2168 4.59279 0.655109 3.55544 1.34824C2.23985 2.22729 1.21446 3.47672 0.608964 4.93853C0.00346269 6.40034 -0.154964 8.00887 0.153718 9.56072C0.4624 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.3449 11.4072 15.7832 10.2262 15.9376 8.99731C16.0064 8.4494 15.5523 8 15.0001 8C14.4479 8 14.0087 8.45071 13.9171 8.99528C13.7771 9.82784 13.4622 10.6253 12.989 11.3335C12.3297 12.3203 11.3926 13.0893 10.2962 13.5435C9.19979 13.9976 7.99334 14.1165 6.82941 13.8849C5.66548 13.6534 4.59634 13.0819 3.7572 12.2428C2.91805 11.4037 2.34658 10.3345 2.11506 9.17059C1.88354 8.00666 2.00237 6.80021 2.45651 5.70381C2.91066 4.60741 3.67972 3.67031 4.66645 3.01099C5.37466 2.53779 6.17216 2.22293 7.00472 2.08289C7.54929 1.99129 8 1.55211 8 0.999886Z"
        fill="currentColor"
      />
    </clipPath>
    <foreignObject width="16" height="16" clipPath="url(#clip)">
      <ConicGradient />
    </foreignObject>
  </svg>
);

const ButtonLoadingSpinner = styled(ButtonLoadingIcon, {
  size: '$space$medium',
  marginVertical: -3,
  animation: `${spinnerAnimation} 0.6s linear infinite`,
});

type ButtonVariant = ComponentProps<typeof ButtonContainer>['variant'];

interface SharedProps {
  children?: ReactNode;
  variant?: ButtonVariant;
  block?: boolean;
  title?: string;
  ariaLabel?: string;
  keyboardShortcut?: string;
  icon?: ComponentType<{ className?: string }>;
  tint?: ComponentProps<typeof ButtonContainer>['tint'];
}

interface AnchorProps extends SharedProps {
  as: 'a';
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: '_blank';
  download?: boolean;
}

interface ButtonProps extends SharedProps {
  as: 'button';
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
}

type Props = (AnchorProps | ButtonProps) & {
  iconOnly?: boolean;
};

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (props, ref) => {
    const {
      children,
      variant,
      block,
      as,
      title,
      ariaLabel,
      icon: Icon,
      keyboardShortcut,
      iconOnly,
      tint,
    } = props;

    const innerContent = children && !iconOnly ? (
      variant === 'inline' || variant === 'subtleInline' ? (
        children
      ) : (
        <Text color="inherit" weight="semibold" as="span" size="small">
          {children}
        </Text>
      )
    ) : null;
    const icon = Icon ? <Icon className={iconClassName} /> : null;
    const keyboardIcon = keyboardShortcut ? <Kbd>{keyboardShortcut}</Kbd> : null;

    switch (as) {
      case 'a': {
        const { href, onClick, target, download } = props;
        const anchorRef = ref as ForwardedRef<HTMLAnchorElement>;

        return (
          <ButtonContainer
            as="a"
            href={href}
            variant={variant}
            onClick={onClick}
            block={block}
            target={target}
            rel="noopener noreferrer"
            ref={anchorRef}
            title={title}
            aria-label={ariaLabel}
            download={download}
            tint={tint}
          >
            {keyboardIcon}
            {icon}
            {innerContent}
          </ButtonContainer>
        );
      }
      case 'button': {
        const { disabled, loading, onClick, type = 'button' } = props;
        const buttonRef = ref as ForwardedRef<HTMLButtonElement>;

        return (
          <ButtonContainer
            as="button"
            variant={variant}
            onClick={onClick}
            loading={loading}
            disabled={disabled || loading}
            block={block}
            type={type}
            ref={buttonRef}
            title={title}
            aria-label={ariaLabel}
            tint={tint}
          >
            {loading ? <ButtonLoadingSpinner /> : icon}
            {keyboardIcon}
            {innerContent}
          </ButtonContainer>
        );
      }
      default:
        assertUnreachable(as);
        return null;
    }
  },
);
